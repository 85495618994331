import React from 'react';
import './App.css';
import data from "./data.json";
import Cookies from 'js-cookie';

const itemLookupMap = {
    "Primary Weapon": {
        "Assault": ["Automatic Rifle"],
        "Medic": ["Automatic Rifle", "Carbine", "Personal Defence Weapon", "Submachine Gun"],
        "Engineer": ["Automatic Rifle", "Carbine", "Personal Defence Weapon", "Submachine Gun"],
        "Support": ["Light Machine Gun", "Light Support Gun"],
        "Recon": ["Designated Marksman Rifle", "Sniper Rifle"]
    },
    "Secondary Weapon": {
        "Assault": ["Automatic Pistol", "Pistol", "Heavy Caliber Pistol"],
        "Medic": ["Automatic Pistol", "Pistol", "Heavy Caliber Pistol"],
        "Engineer": ["Automatic Pistol", "Pistol", "Heavy Caliber Pistol"],
        "Support": ["Automatic Pistol", "Pistol", "Heavy Caliber Pistol"],
        "Recon": ["Automatic Pistol", "Pistol", "Heavy Caliber Pistol"]
    },
    "Primary Gadget": {
        "Assault": ["Primary Gadget"],
        "Medic": ["Primary Gadget"],
        "Engineer": ["Primary Gadget"],
        "Support": ["Primary Gadget"],
        "Recon": ["Primary Gadget"]
    },
    "Secondary Gadget": {
        "Assault": ["Secondary Gadget"],
        "Medic": ["Secondary Gadget"],
        "Engineer": ["Secondary Gadget"],
        "Support": ["Secondary Gadget"],
        "Recon": ["Secondary Gadget"]
    },
    "Throwables": {
        "Assault": ["Throwables"],
        "Medic": ["Throwables"],
        "Engineer": ["Throwables"],
        "Support": ["Throwables"],
        "Recon": ["Throwables"]
    },
}

function getItemsForCategoryAndClass(category, classToCheck, state) {
    for (const item of data) {
        if (item.Category === category) {
            let filteredItems = item.Items.filter((i) => i[classToCheck]);
            if (Number(state.level) !== 0) {
                filteredItems = filteredItems.filter((i) => i.Level <= state.level);
            }
            return filteredItems;
        }
    }
    return []
}

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            level: 0,
            currentPage: "Loadout Roulette",
            roulette: {
                Class: "",
                PrimaryWeapon: "",
                SecondaryWeapon: "",
                PrimaryGadget: "",
                SecondaryGadget: "",
                Throwable: ""
            }
        };
    }

    componentDidMount() {
        const level = Cookies.get('level');
        if (level) {
            return this.setState({level: level})
        } else {
            return Cookies.set('level', 0);
        }
    }

    handleChange = (event) => {
        Cookies.set('level', event.target.value);
        return this.setState({level: event.target.value})
    };

    changePage = (page) => {
        return this.setState({currentPage: page})
    };

    rollLoadout = () => {

        const randomClass = Object.keys(itemLookupMap["Primary Weapon"])[Math.floor(Math.random() * Object.keys(itemLookupMap["Primary Weapon"]).length)];

        function rollForItem(itemCategory, chosenClass, state) {
            const RolledItemCategory = itemLookupMap[itemCategory][chosenClass][Math.floor(Math.random() * itemLookupMap["Primary Weapon"][chosenClass].length)];
            const itemsForCategoryAndClass = getItemsForCategoryAndClass(RolledItemCategory, randomClass, state);

            if (itemsForCategoryAndClass.length === 0) {
                return rollForItem(itemCategory, chosenClass, state);
            }

            return itemsForCategoryAndClass[Math.floor(Math.random() * itemsForCategoryAndClass.length)];
        }

        const randomPrimaryWeapon = rollForItem("Primary Weapon", randomClass, this.state);
        const randomSecondaryWeapon = rollForItem("Secondary Weapon", randomClass, this.state);
        const randomPrimaryGadget = rollForItem("Primary Gadget", randomClass, this.state);
        const randomSecondaryGadget = rollForItem("Secondary Gadget", randomClass, this.state);
        const randomThrowable = rollForItem("Throwables", randomClass, this.state);

        return this.setState({
            roulette: {
                Class: randomClass,
                PrimaryWeapon: randomPrimaryWeapon,
                SecondaryWeapon: randomSecondaryWeapon,
                PrimaryGadget: randomPrimaryGadget,
                SecondaryGadget: randomSecondaryGadget,
                Throwable: randomThrowable
            }
        })
    };


    render() {
        return (
            <>
                <nav className="navbar navbar-expand-lg bg-primary" data-bs-theme="dark">
                    <div className="container">
                        <button className="navbar-brand bg-transparent" onClick={() => {
                            this.changePage("Unlocks")
                        }}><img src="./img/logo.png" alt="Bootstrap" width="200px"/>
                        </button>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <button
                                        className={"nav-link " + (this.state.currentPage === "Loadout Roulette" && "active")}
                                        onClick={() => {
                                            this.changePage("Loadout Roulette")
                                        }}>🎰 Loadout Roulette
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={"nav-link " + (this.state.currentPage === "Unlocks" && "active")}
                                            aria-current="page" onClick={() => {
                                        this.changePage("Unlocks")
                                    }}>🔒 Unlocks
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <a href="https://store.steampowered.com/app/671860?utm_source=BattlebitRoulette&utm_medium=web"
                                       className="nav-link" aria-current="page" target="_blank"
                                       rel="noopener noreferrer">🛒 Buy Battlebit </a>
                                </li>
                            </ul>
                            <div className="d-flex text-white">
                                <div className="row g-2 align-items-center">
                                    <div className="col-auto">
                                        <label htmlFor="inputLevelNumber" className="col-form-label">Level</label>
                                    </div>
                                    <div className="col-auto">
                                        <input className="form-control me-2" id="inputLevelNumber" type="number"
                                               onChange={this.handleChange} value={this.state.level} min="0" max="200"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>

                {this.state.currentPage === "Unlocks" && (
                    <div className="ui container" style={{minHeight: "80vh"}}>
                        <div className="h-100 p-4 text-white bg-dark border rounded-3 my-4">
                            <h2>🔒 Unlocks</h2>
                            <p className="alert alert-secondary" role="alert">Enter your level above to see what items
                                you have already unlocked, or check what level you unlock that sweet, sweet gun.</p>
                        </div>
                        {data.map((category) => (
                            <>
                                <div className="h-100 p-4 text-white bg-dark border rounded-3 my-4">
                                    <h3 className="mb-4">{category.Category}</h3>
                                    <div className="list-group">
                                        {category.Items.map((item) => (
                                            <>
                                                <div
                                                    className="list-group-item d-flex justify-content-between align-items-center">
                                                    <div className="container">
                                                        <div className="row weaponrow">
                                                            <div className="bg"
                                                                 style={{backgroundImage: `url(${"./img/icons/" + item.Name.replace(/ /g, "") + ".png"})`}}></div>
                                                            <div className="col-9">
                                                                <h4>{item.Name}</h4>
                                                                <div className="d-flex flex-row flex-wrap">
                                                                    <span
                                                                        className={"p-2 flex-fill m-2 badge " + (item.Leader ? "text-bg-primary" : "text-muted")}>Leader</span>
                                                                    <span
                                                                        className={"p-2 flex-fill m-2 badge " + (item.Assault ? "text-bg-secondary" : "text-muted")}>Assault</span>
                                                                    <span
                                                                        className={"p-2 flex-fill m-2 badge " + (item.Medic ? "text-bg-success" : "text-muted")}>Medic</span>
                                                                    <span
                                                                        className={"p-2 flex-fill m-2 badge " + (item.Engineer ? "text-bg-danger" : "text-muted")}>Engineer</span>
                                                                    <span
                                                                        className={"p-2 flex-fill m-2 badge " + (item.Support ? "text-bg-warning" : "text-muted")}>Support</span>
                                                                    <span
                                                                        className={"p-2 flex-fill m-2 badge " + (item.Recon ? "text-bg-info" : "text-muted")}>Recon</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div
                                                                    className={"badge level " + (this.state.level >= item.Level ? 'bg-success' : 'bg-secondary')}>
                                                                    <span>LEVEL</span>
                                                                    <p>{item.Level}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                )}

                {this.state.currentPage === "Loadout Roulette" && (
                    <div className="ui container" style={{minHeight: "80vh"}}>
                        <div className="h-100 p-5 text-white bg-dark border rounded-3 my-4">
                            <h2>🎰 Loadout Roulette</h2>
                            <p>Feel up to the challenge? Hit the button below to be given a random loadout. <br/> <i>Note,
                                if you have entered your character level above, items up to your rank will only be
                                available. If left at 0, all items will be available.</i></p>
                            <p className="alert alert-secondary" role="alert">Be sure to share videos / screenshots on
                                social media with the hashtag; #BattlebitRoulette - so we can all enjoy the
                                craziness!</p>
                            <button className="btn btn-outline-light mt-5" type="button" onClick={() => {
                                this.rollLoadout()
                            }}>🎲 Roll another Loadout
                            </button>
                        </div>
                        {this.state.roulette.Class !== "" && (
                            <div className="h-100 p-5 text-white bg-dark border rounded-3 my-4">
                                <div className="list-group">
                                    <div className="list-group-item d-flex justify-content-between align-items-center">
                                        <div className="container">
                                            <div className="row weaponrow">
                                                <div className="bg"
                                                     style={{backgroundImage: `url(${"./img/icons/" + this.state.roulette.Class.replace(/ /g, "") + ".png"})`}}></div>
                                                <div className="col-9">
                                                    <h4>{this.state.roulette.Class}</h4>
                                                    <div className="d-flex flex-row flex-wrap">
                                                        <span
                                                            className={"p-2 flex-fill m-2 badge " + (this.state.roulette.Class === 'Leader' ? "text-bg-primary" : "text-muted")}>Leader</span>
                                                        <span
                                                            className={"p-2 flex-fill m-2 badge " + (this.state.roulette.Class === 'Assault' ? "text-bg-secondary" : "text-muted")}>Assault</span>
                                                        <span
                                                            className={"p-2 flex-fill m-2 badge " + (this.state.roulette.Class === 'Medic' ? "text-bg-success" : "text-muted")}>Medic</span>
                                                        <span
                                                            className={"p-2 flex-fill m-2 badge " + (this.state.roulette.Class === 'Engineer' ? "text-bg-danger" : "text-muted")}>Engineer</span>
                                                        <span
                                                            className={"p-2 flex-fill m-2 badge " + (this.state.roulette.Class === 'Support' ? "text-bg-warning" : "text-muted")}>Support</span>
                                                        <span
                                                            className={"p-2 flex-fill m-2 badge " + (this.state.roulette.Class === 'Recon' ? "text-bg-info" : "text-muted")}>Recon</span>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className={"badge level bg-success"}>
                                                        <span>LEVEL</span>
                                                        <p>N/A</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {[this.state.roulette.PrimaryWeapon, this.state.roulette.SecondaryWeapon, this.state.roulette.PrimaryGadget, this.state.roulette.SecondaryGadget, this.state.roulette.Throwable,].map((item) => (
                                        <>
                                            <div
                                                className="list-group-item d-flex justify-content-between align-items-center">
                                                <div className="container">
                                                    <div className="row weaponrow">
                                                        <div className="bg"
                                                             style={{backgroundImage: `url(${"./img/icons/" + item.Name.replace(/ /g, "") + ".png"})`}}></div>
                                                        <div className="col-9">
                                                            <h4>
                                                                <small>{item.Category}</small><br/>
                                                                {item.Name}
                                                            </h4>
                                                            <div className="d-flex flex-row flex-wrap">
                                                                <span
                                                                    className={"p-2 flex-fill m-2 badge " + (item.Leader ? "text-bg-primary" : "text-muted")}>Leader</span>
                                                                <span
                                                                    className={"p-2 flex-fill m-2 badge " + (item.Assault ? "text-bg-secondary" : "text-muted")}>Assault</span>
                                                                <span
                                                                    className={"p-2 flex-fill m-2 badge " + (item.Medic ? "text-bg-success" : "text-muted")}>Medic</span>
                                                                <span
                                                                    className={"p-2 flex-fill m-2 badge " + (item.Engineer ? "text-bg-danger" : "text-muted")}>Engineer</span>
                                                                <span
                                                                    className={"p-2 flex-fill m-2 badge " + (item.Support ? "text-bg-warning" : "text-muted")}>Support</span>
                                                                <span
                                                                    className={"p-2 flex-fill m-2 badge " + (item.Recon ? "text-bg-info" : "text-muted")}>Recon</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div
                                                                className={"badge level " + (this.state.level >= item.Level ? 'bg-success' : 'bg-secondary')}>
                                                                <span>LEVEL</span>
                                                                <p>{item.Level}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <footer className="bg-dark p-4">
                    <div className="container small text-center">
                        We use cookies to store your level (so you don't have to set it each time you visit the site).
                        <br/>
                        We are not affiliated in any way with the Battlebit developers, this is purely a fan site.
                    </div>
                </footer>
            </>
        )
    }
}
